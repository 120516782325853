import React from "react";
import { Link } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const ThankYou = () => {
  return (
    <>
      <div className="my-10 flex flex-col justify-center items-center gap-10">
        <FaCheckCircle className="text-[13rem] text-accent" />
        <p className="text-3xl lg:text-5xl text-center text-white">
          Thank You for contacting us.
        </p>
        <Link to="/">
          <button className="text-white bg-accent">Go to Home</button>
        </Link>
      </div>
    </>
  );
};

export default ThankYou;
