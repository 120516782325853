import React from "react";
import { HeadBanner } from "../components";

const OneWay = () => {
  return (
    <>
      <HeadBanner title="One Way Cab" />
      <div className="flex flex-col justify-center items-center py-20 bg-white">
        <p className="xl:text-5xl text-2xl mb-20 font-bold text-center">
          One Way Cab Services
        </p>
        <div className="grid xl:grid-cols-2 grid-cols-1 xl:w-3/5 w-11/12 gap-10">
          <div className="mt-10 gradient p-10 rounded-xl ">
            <p className="font-bold text-xl text-center">
              THE BEST SERVICE PROVIDERS FOR ONE-WAY TRAVELLING
            </p>
            <p className="mt-2 text-justify">
              You can now book a one-way taxi between routes wherever the
              service is available. If you are looking for a drop service,
              Jainil Travels is the best taxi providing platform to hire a
              one-way taxi at low rates. Find the route listing for one-way taxi
              services and book the same route if needed. We are committed to
              providing the best and affordable one-way car rental service for
              your booking.
            </p>
          </div>
          <div className="mt-10 gradient p-9 rounded-xl">
            <p className="font-bold text-xl text-center">
              AHMEDABAD TO UDAIPUR CAB SERVICE ONE WAY AND ROUND JOURNEY
            </p>
            <p className="mt-2 text-justify">
              Planning a trip to Ahmedabad or Udaipur? Our premium round trip
              service provides you with the most comfortable ride to and from
              your doorstep. Our drivers are skilled, experienced and courteous.
              We promise exemplary service, a professional driver to guide you
              through your journey, and a clean and sanitized vehicle for your
              comfort. Book a one way or a round trip journey now!
            </p>
          </div>
        </div>

        <div className="text-justify rounded-xl gradient p-10 my-10 xl:w-3/5 w-11/12">
          <p className="text-xl font-bold text-center round">
            INTERESTED IN BOOKING AN AHMEDABAD TO UDAIPUR CAB?
          </p>
          <p className="text-justify mt-2 ">
            Jainil Travels offers services that cover long distance travelling
            from any cities we are active in, at the highest possible comfort.
            This is where we come in. With our fleet of luxury vehicles made
            available to you while remaining affordable. What are you waiting
            for? Book a journey of a lifetime with our trusted and outperformed
            services with transparent prices at the most affordable rates.
          </p>
        </div>
        <div className="mt-10 col-span-2 rounded-xl p-8 border my-10 border-l-4 border-l-black border-slate-300 shadow-2xl xl:w-3/5 w-11/12">
          <p className="text-2xl text-center font-bold round">
            The Best Taxi Service From Ahmedabad To Mumbai Is Jainil Travels
          </p>
        </div>
        <div className="grid xl:grid-cols-2 xl:w-3/5 w-11/12 gap-10">
          <div className="mt-10 gradient p-10 rounded-xl ">
            <p>
              Jainil Travels is known to transcend all other travel agencies
              because we place primary importance to provide only the
              experienced and courteous drivers towards all your journey
              requirements. When recruiting drivers to partner with us, they
              undergo a rigorous process of selection and after meeting many
              requirements, they join us.
            </p>
          </div>
          <div className="mt-10 gradient p-10 rounded-xl ">
            <p>
              From the start, we believe that cleanliness is one of the greatest
              contributing factors to all our satisfied customers through the
              years. All our vehicles are sanitized before and after a journey
              with a customer is completed. Even in trying times where one’s
              health is of great importance; we ensure that all our partners
              maintain the safety norms throughout the trip.
            </p>
          </div>
          <div className=" gradient p-10 rounded-xl  ">
            <p>
              We have a dedicated 24×7 customer support team always at your
              service to help solve any problem during your journey. We are here
              to help round-the-clock with any issue in the booking, travelling
              and billing of your trip. Understanding a customer’s feedback is
              vital to our growth. So, reach out to us, and we will make sure
              that we satisfy your query.
            </p>
          </div>
          <div className="gradient p-10 rounded-xl  ">
            <p>
              From humble beginnings to a huge customer base, all along the
              journey we have brought a peace of mind, and a smile to every
              traveler. We have countless testimonies of how journeying with us
              has directly or indirectly impacted many lives. Our courteous and
              helpful partners have made many customers satisfied. Journey with
              us to experience the same!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default OneWay;
