import React from "react";
import { Routes, Route } from "react-router-dom";
import { Footer, Navbar } from "./components";
import ScrollToTop from "./components/ScrollToTop";
import {
  Home,
  NotFound,
  Introduction,
  Contact,
  About,
  RoundTrip,
  OneWay,
} from "./screens";
import Inquiry from "./screens/Inquiry";
import ThankYou from "./screens/ThankYou";

const App = () => {
  return (
    <>
      <ScrollToTop>
        <Navbar />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/thankyou" element={<ThankYou />} />
          <Route path="/" element={<Home />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/round-trip" element={<RoundTrip />} />
          <Route path="/one-way" element={<OneWay />} />
          <Route path="/inquiry" element={<Inquiry />} />
        </Routes>
        <Footer />
      </ScrollToTop>
    </>
  );
};

export default App;
