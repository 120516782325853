import React from "react";
import { HeadBanner } from "../components";

const Introduction = () => {
  return (
    <>
      <HeadBanner title="Introduction" />
      <div className="bg-black flex flex-col items-center justify-center gap-20 py-20">
        <p className="text-accent font-medium lg:text-4xl">
          INTRODUCTION OF JAINILTRAVELS
        </p>
        <div className="relative pb-20 bg-black text-white w-11/12  xl:w-3/4">
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-flow-col lg:gap-28 gap-14 lg:after:border-r-2 lg:before:border-r-0 xs:before:border-r-2 relative lg:h-48 h-80">
              <p className="font-medium text-justify">
                Janiltravels began their journey in the year 2015 with entering
                into the travel agency in Ahmedabad as a top player in this
                category. Started with humbled beginnings and the commitment to
                satisfy every passenger’s trip has been the objective from the
                start.
              </p>
              <div className="h-20 w-20 lg:h-32 lg:w-32 gradient rounded-full absolute -left-[40px] lg:left-[90%] border-[5px] border-white"></div>
            </div>
            <div></div>
          </div>
          <div className="grid lg:grid-cols-2">
            <div></div>
            <div className="grid grid-flow-col lg:gap-28 gap-14 before:border-r-2 relative lg:h-48 h-80">
              <p className="font-medium text-justify">
                Janiltravels began their journey in the year 2015 with entering
                into the travel agency in Ahmedabad as a top player in this
                category. Started with humbled beginnings and the commitment to
                satisfy every passenger’s trip has been the objective from the
                start.
              </p>
              <div className="w-20 h-20 lg:h-32 lg:w-32 gradient rounded-full absolute -left-[40px] lg:-left-[64px] border-[4px] border-white"></div>
            </div>
          </div>
          <div className="grid lg:grid-cols-2">
            <div className="grid grid-flow-col lg:gap-28 gap-14 lg:after:border-r-2 before:border-r-2  lg:before:border-r-0 relative lg:h-48 h-80">
              <p className="font-medium text-justify">
                In the course of its journey, the company has established a
                strong position in the travel industry. Believing that customer
                satisfaction is as important as products and services, has
                allowed this company to acquire a huge customer base that is
                growing day by day.
              </p>
              <div className="h-20 w-20 lg:h-32 lg:w-32 gradient rounded-full absolute -left-[40px]  lg:left-[92%] border-[5px] border-white"></div>
            </div>
            <div></div>
          </div>
          <div className="grid lg:grid-cols-2">
            <div></div>
            <div className="grid grid-flow-col lg:gap-28 gap-14 before:border-r-2 relative lg:h-48 h-80">
              <p className="font-medium text-justify">
                As they began to grow step by step, the agency recruited teams
                who are dedicated to their roles and focused in achieving the
                company’s shared vision and larger goals, with the objective to
                meet customer satisfaction and recognition among the community.
              </p>
              <div className="w-20 h-20 lg:h-32 lg:w-32 gradient rounded-full absolute -left-[40px] lg:-left-[60px] border-[4px] border-white"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Introduction;
