import React, { useState } from "react";
import img from "../assets/bg.png";
import logo from "../assets/logo.webp";
import {
  FaBars,
  FaEnvelope,
  FaFacebook,
  FaYoutube,
  FaTimes,
  FaUserCircle,
  FaChevronCircleUp,
  FaInstagram,
} from "react-icons/fa";

import { Link } from "react-router-dom";
import Whatsapp from "../assets/whatsapp.svg";

const Navbar = () => {
  const [top, setTop] = useState(false);

  const handleTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const hideScroll = () => {
    if (window.scrollY >= 80) {
      setTop(true);
    } else {
      setTop(false);
    }
  };

  window.addEventListener("scroll", hideScroll);

  const [nav, setNav] = useState(false);

  return (
    <>
      <div className="grid xs:grid-cols-1 lg:grid-cols-3 px-20 py-5 pt-0">
        <img
          src={img}
          alt=""
          className="h-screen w-screen fixed left-0 right-0 top-0 bottom-0 -z-50 object-cover"
        />
        <div className="text-white xs:justify-center">
          <ul className="flex flex-col gap-3 py-10 ">
            <a rel="noreferrer" href="tel:+919510429400">
              <li className="mx-2 font-medium flex items-center hover:text-[#ffc000] cursor-pointer xs:text-sm xs:justify-center">
                <FaUserCircle className="mr-2" />
                +91 9510429400
              </li>
            </a>
            <a rel="noreferrer" href="mailto:info@jainiltravels.com">
              <li className="mx-2 font-medium flex items-center  hover:text-[#ffc000] cursor-pointer xs:text-sm xs:justify-center">
                <FaEnvelope className="mr-2" />
                info@jainiltravels.com
              </li>
            </a>
          </ul>
        </div>
        <div className="flex items-center justify-center ">
          <img src={logo} alt="" className="h-24" />
        </div>
        <div className="flex items-center justify-end text-white text-xl xs:justify-center my-5">
          <div className="flex gap-7 items-center">
            <a
              href="https://www.facebook.com/Jainil-Travel-102296445985091"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook />
            </a>
            <a
              href="https://www.instagram.com/jainiltravels/"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.youtube.com/channel/UCR2ygXErvJ7KTJmyvX1ZORQ/featured"
              target="_blank"
              rel="noreferrer"
            >
              <FaYoutube />
            </a>
          </div>
        </div>
      </div>

      <nav
        className={`${
          top ? "gradient rounded-none" : "lg:mx-40 gradient lg:rounded-full"
        } sticky top-0 z-50 p-6 text-white md:flex md:items-center md:justify-center flex justify-center items-center md:text-xs font-medium lg:text-base xs:text-justify`}
      >
        {!nav ? (
          <FaBars
            className="text-2xl font-medium  cursor-pointer mx-2 md:hidden block absolute top-3 left-5"
            onClick={() => setNav(!nav)}
          />
        ) : (
          <FaTimes
            className="text-2xl cursor-pointer mx-2 md:hidden block absolute top-5 left-5"
            onClick={() => setNav(!nav)}
          />
        )}

        <ul
          className={`${
            nav ? "flex flex-col md:flex-row" : "hidden"
          } md:flex items-center md:z-auto md:static  w-auto left-0 transition-all ease-in duration-100 justify-between`}
        >
          <Link to="/" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline ">
              HOME
            </li>
          </Link>
          <Link to="/introduction" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              INTRODUCTION
            </li>
          </Link>
          <Link to="/about" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              ABOUT US
            </li>
          </Link>
          <Link to="/one-way" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              ONE WAY CAB
            </li>
          </Link>
          <Link to="/round-trip" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              ROUND TRIP CAB
            </li>
          </Link>
          <Link to="/inquiry" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              INQUIRY
            </li>
          </Link>
          <Link to="/contact" onClick={() => setNav(!nav)}>
            <li className="text-sm font-bold mx-4 my-3 md:my-0 hover:underline">
              CONTACT US
            </li>
          </Link>
        </ul>
      </nav>

      <div
        className={`fixed bottom-5 right-5 flex items-center justify-center ${
          !top ? "hidden" : "block"
        }`}
      >
        <div className="h-14 w-14 bg-accent absolute rounded-full animate-ping opacity-20 -z-50"></div>
        <p
          className="navbar rounded-full h-14 w-14 text-white inline-flex gradient"
          onClick={() => handleTop()}
        >
          <FaChevronCircleUp className="h-14 w-14 navbar rounded-full" />
        </p>
      </div>

      <div className="fixed bottom-5 left-5 z-50">
        <a
          href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
          target="_blank"
          rel="noreferrer"
        >
          <img src={Whatsapp} alt="" className="h-12 w-12 z-50" />
        </a>
      </div>

      <div className="fixed -right-9 top-1/2 bg-accent text-white p-2 px-5 -rotate-90 rounded-md">
        <a href="tel:+91 9510429400" className="">Call Now</a>
      </div>
    </>
  );
};

export default Navbar;
