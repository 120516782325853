import React from "react";
import { FaChevronRight } from "react-icons/fa";

const HeadBanner = ({ title }) => {
  return (
    <>
      <div className="relative w-screen min-h-[40vh] banner flex items-center justify-center  py-32">
        <div className="text-white text-center">
          <p className="text-xl font-medium flex items-center justify-center">
            <span className="text-accent">Home</span>{" "}
            <FaChevronRight className="text-sm mt-1 mx-3" />
            {title}
          </p>
          <p className="text-5xl font-bold mt-4">{title}</p>
        </div>
      </div>
    </>
  );
};

export default HeadBanner;
