import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadBanner } from "../components";

const Inquiry = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [tripType, setTripType] = useState("");
  const [pickup, setPickup] = useState("");
  const [drop, setDrop] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    console.log(tripType);

    const data = {
      name,
      email: "no-apply",
      mobile,
      subject: "no-apply",
      message: "no-apply",
      tripType,
      pickup,
      drop,
    };

    await fetch("https://api.jainiltravels.com/sendInquiryEmail", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      body: JSON.stringify(data),
    }).then(navigate("/thankyou"));

    setEmail("");
    setSubject("");
    setMobile("");
    setMessage("");
    setName("");
    setTripType("");
    setPickup("");
    setDrop("");
  };
  return (
    <>
      <HeadBanner title="Inquiry" />

      <div className="flex items-center justify-center bg-white">
        <div className="xl:w-2/5 w-11/12 my-20">
          <p className="font-bold text-5xl text-center">Inquiry</p>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center items-center text-lg gap-5 mt-20 mb-10">
              <label>One Way</label>
              <input
                type="radio"
                value="one-way"
                name="trip"
                checked={tripType === "One Way"}
                onChange={() => setTripType("One Way")}
              />
              <label>Round Trip</label>
              <input
                type="radio"
                value="round-trip"
                name="trip"
                checked={tripType === "Round Trip"}
                onChange={() => setTripType("Round Trip")}
              />
            </div>
            <div className="grid grid-cols-2 gap-5 my-10">
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="number"
                pattern="[0-9]{10}"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Pick Up Address"
                value={pickup}
                onChange={(e) => setPickup(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Drop Off Address"
                value={drop}
                onChange={(e) => setDrop(e.target.value)}
                required
              />
            </div>
            <button className="w-full gradient">Book Now</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Inquiry;
