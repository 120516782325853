import React from "react";
import { HeadBanner } from "../components";
import about from "../assets/about-us.webp";

const About = () => {
  return (
    <>
      <HeadBanner title="About Us" />
      <div className=" flex flex-col justify-center items-center py-10 xs:px-5 lg:pl-0 bg-white">
        <div className="grid lg:grid-cols-2 grid-cols-1 lg:w-3/4 lg:gap-10">
          <div className="flex items-center justify-center">
            <img src={about} alt="" className="" />
          </div>

          <div>
            <p className="text-4xl font-bold mt-20 ">About Us</p>
            <p className="mt-10 text-lg text-justify leading-relaxed ">
              Janiltravels began their journey in the year 2015 with entering
              into the travel agency in Ahmedabad as a top player in this
              category. Since they’re launch over a decade ago, they have
              provided transportation services to scores of customers. They have
              provided services that meet the needs and the options of various
              modes of transportation readily available to the community.
            </p>
            <p className="mt-5 text-lg text-justify leading-relaxed">
              They cater to the varied requirements of their customers. The
              staff at this establishment are courteous and prompt at providing
              any assistance and are readily available to answer any queries or
              questions that any customer may have.
            </p>
          </div>

          <p className="xs:mt-5 text-lg text-justify lg:col-span-2 leading-loose ">
            Their fleet of transports backed by their dedicated support team has
            fused a combination that has left passengers satisfied beyond their
            expectations. As a leading agency in the travel industry, they have
            strived for excellency at all times, ensuring that they achieve the
            company’s shared vision and purpose. To the community at large, they
            have built a good name for their travels from the ground up, and as
            they grow they hope to continue to satisfy every traveler one
            journey at a time.
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
