import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadBanner } from "../components";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [tripType, setTripType] = useState("");
  const [pickup, setPickup] = useState("");
  const [drop, setDrop] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = {
      name,
      email,
      mobile,
      subject,
      message,
      tripType,
      pickup,
      drop,
    };

    await fetch("https://api.jainiltravels.com/sendInquiryEmail", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      mode: "no-cors",
      body: JSON.stringify(data),
    }).then(navigate("/thankyou"));

    setEmail("");
    setSubject("");
    setMobile("");
    setMessage("");
    setName("");
    setTripType("");
    setPickup("");
    setDrop("");
  };
  return (
    <>
      <HeadBanner title="Contact Us" />

      <div className="flex items-center justify-center bg-white">
        <div className="xl:w-2/5 w-11/12 my-20">
          <p className="font-bold mb-3 text-center">Contact with us now</p>
          <p className="font-bold text-5xl text-center">Leave a message</p>
          <form onSubmit={handleSubmit}>
            <div className="flex justify-center items-center text-lg gap-5 mt-20 mb-10">
              <label>One Way</label>
              <input
                type="radio"
                value="one-way"
                name="trip"
                checked={tripType === "One Way"}
                onChange={() => setTripType("One Way")}
              />
              <label>Round Trip</label>
              <input
                type="radio"
                value="round-trip"
                name="trip"
                checked={tripType === "Round Trip"}
                onChange={() => setTripType("Round Trip")}
              />
            </div>
            <div className="grid grid-cols-2 gap-5 my-10">
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Pick Up Address"
                value={pickup}
                onChange={(e) => setPickup(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Drop Off Address"
                value={drop}
                onChange={(e) => setDrop(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="number"
                pattern="[0-9]{10}"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Mobile Number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                required
              />
              <input
                type="text"
                className="h-16 w-full border bg-[#F3F3F3] rounded-lg px-5 font-medium focus:outline-0 focus:border-0"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
            </div>
            <textarea
              cols="30"
              rows="10"
              placeholder="Write a Message"
              className="w-full border bg-[#F3F3F3] rounded-lg p-5 font-medium focus:outline-0 focus:border-0"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <button className="w-full gradient my-10">Book Now</button>
          </form>
        </div>
      </div>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3672.504780681044!2d72.51409991448843!3d23.005231122750015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9ad46d96c81f%3A0x92d66f27b8d2597f!2sSuyog%20Complex%201%2C%20Vejalpur%20Rd%2C%20Vejalpur%2C%20Ahmedabad%2C%20Gujarat%20380051!5e0!3m2!1sen!2sin!4v1663916445575!5m2!1sen!2sin"
        width="100%"
        className="h-[50vh]"
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Contact Map"
      />
    </>
  );
};

export default Contact;
