import React from "react";
import Logo from "../assets/logo.webp";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { FaAddressCard, FaEnvelope, FaPhone } from "react-icons/fa";
import { Link } from "react-router-dom";
import FLogo from "../assets/footer.png";

const Footer = () => {
  return (
    <>
      <div className="bg-black flex flex-col justify-center items-center py-10 xs:p-5 text-white">
        <div className="grid lg:grid-cols-3 grid-cols-1 xl:w-3/4 2xl:w-3/5 w-11/12 gap-10">
          <div className="my-10">
            <img src={Logo} alt="" />
          </div>
          <div>
            <p className="text-2xl font-bold mb-5">QUICK LINKS</p>
            <ul className="flex flex-col gap-4">
              <Link to="/">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> Home
                </li>
              </Link>
              <Link to="/introduction">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> Introduction
                </li>
              </Link>
              <Link to="/about">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> About Us
                </li>
              </Link>
              <Link to="/one-way">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> One Way Cab
                </li>
              </Link>
              <Link to="/round-trip">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> Round Trip Cab
                </li>
              </Link>
              <Link to="/inquiry">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> Inquiry
                </li>
              </Link>
              <Link to="/contact">
                <li className="flex items-center gap-3 hover:text-accent cursor-pointer">
                  <BsFillArrowRightCircleFill /> Contact Us
                </li>
              </Link>
            </ul>
          </div>
          <div>
            <p className="xs:text-lg lg:text-2xl font-bold mb-5">
              CONNECT WITH US
            </p>
            <p className="grid grid-flow-col justify-start gap-3 my-4">
              <FaAddressCard className="mt-1" /> Indra Nagar, Suyog-1 Behind
              Vejalpur, Ahmedabad-380051
            </p>
            <a
              className="my-3 grid grid-flow-col justify-start text-sm font-bold gap-3"
              href="mailto:info@jainiltravels.com"
              target="_blank"
              rel="noreferrer"
            >
              <FaEnvelope className="mt-1" /> info@jainiltravels.com
            </a>
            <a
              className="my-3 grid grid-flow-col justify-start text-sm font-bold gap-3"
              href="mailto:jainiltravels@gmail.com "
              target="_blank"
              rel="noreferrer"
            >
              <FaEnvelope className="mt-1" /> jainiltravels@gmail.com
            </a>
            <a
              className="my-3 grid grid-flow-col justify-start text-sm font-bold gap-3"
              href="tel:+919510429400"
              target="_blank"
              rel="noreferrer"
            >
              <FaPhone className="mt-1" /> +91 9510429400
            </a>
          </div>
        </div>
        <p className="lg:mt-10 xs:text-center flex items-center">
          {new Date().getFullYear()} &copy; JAINIL TRAVEL | All Rights Reserved.
          Creator{" "}
          <a
            href="//www.samcomtechnologies.com/"
            target="_blank"
            rel="noreferrer"
            className="mx-2"
          >
            <img src={FLogo} alt="" className="w-5" />
          </a>
        </p>
      </div>
    </>
  );
};

export default Footer;
