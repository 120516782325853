import React from "react";
// import { HeadBanner } from "../components";
import Swift from "../assets/swift.webp";
import Innova from "../assets/innova.webp";
import Innova2 from "../assets/innova2.webp";
import Ertiga from "../assets/ertiga.webp";
import card1 from "../assets/card1.webp";
import card2 from "../assets/card2.webp";
import card3 from "../assets/card3.webp";

import {
  FaArrowAltCircleRight,
  FaArrowAltCircleLeft,
  FaPlane,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Home = () => {
  const carData = [
    {
      name: "AHMEDABAD - BARODA",
      sedan: "1799",
      suv: "2699",
    },
    {
      name: "AHMEDABAD - SURAT",
      sedan: "3599",
      suv: "3999",
    },
    {
      name: "AHMEDABAD - RAJKOT",
      sedan: "2499",
      suv: "2999",
    },
    {
      name: "AHMEDABAD - JAMNAGAR",
      sedan: "4500",
      suv: "5500",
    },
    {
      name: "AHMEDABAD - UDAIPUR",
      sedan: "3599",
      suv: "4599",
    },
    {
      name: "AHMEDABAD - BHAVNAGAR",
      sedan: "2699",
      suv: "3199",
    },
    {
      name: "AHMEDABAD - MUMBAI",
      sedan: "7500",
      suv: "8999",
    },
    {
      name: "AHMEDABAD - PUNE",
      sedan: "9800",
      suv: "11000",
    },
    {
      name: "AHMEDABAD - JAIPUR",
      sedan: "8700",
      suv: "11500",
    },
    {
      name: "AHMEDABAD - Gandhidham",
      sedan: "3699",
      suv: "4199",
    },
  ];

  return (
    <>
      <div className="flex flex-col justify-center items-center lg:py-40 py-20">
        <div className="2xl:w-3/5 xl:w-3/4 w-11/12 text-center">
          <div className="">
            <p className="lg:text-6xl text-3xl text-white font-bold lg:leading-[5rem]">
              Welcome to Jainil Travels
            </p>
            <p className="lg:text-center text-justify text-white lg:text-lg leading-relaxed my-10">
              We was established in the year 2015 with a taxi tourism operation
              in Ahmedabad initially. Over a period of time, we offers a range
              of services, comforts, and customer care, adhering to the highest
              levels of transportation quality
            </p>
            <div className="flex lg:flex-row flex-col items-center justify-center gap-5">
              <Link to="/inquiry">
                <button
                  className="uppercase font-bold hover:text-black gradient"
                  style={{ background: "white" }}
                >
                  Inquiry Now
                </button>
              </Link>
              <a href="tel:+919510429400" target="_blank" rel="noreferrer">
                <button
                  className="uppercase font-bold hover:text-black"
                  style={{ background: "white" }}
                >
                  +91 9510429400
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#111111] text-white flex flex-col justify-center items-center py-20">
        <div className="2xl:w-3/5 xl:w-3/4 w-11/12">
          <div className="">
            <p className="lg:text-6xl text-3xl text-center text-white font-bold lg:leading-[5rem]">
              OUR SERVICES
            </p>
            <div className="grid xl:grid-cols-3 grid-cols-1 mt-20 gap-10">
              <div className="flex flex-col items-center justify-between gap-5">
                <div className="h-28 w-28 gradient p-5 rounded-full flex items-center justify-center">
                  <FaArrowAltCircleRight className="w-full h-full text-[#111111]" />
                </div>
                <p className="font-bold text-lg">OUTSTATION ONE WAY</p>
                <p className="text-center">
                  Airport and Railway station Pick up and Drop 24/7 any time
                  service available.
                </p>
                <Link to="/contact">
                  <button
                    className="px-14 uppercase font-bold text-black hover:text-black"
                    style={{ background: "white" }}
                  >
                    Book Now
                  </button>
                </Link>
              </div>
              <div className="xl:border-x-4 border-0 px-10 border-white flex flex-col items-center justify-between gap-5">
                <div className="h-28 w-28 gradient p-5 rounded-full flex items-center justify-center">
                  <FaArrowAltCircleLeft className="w-full h-full text-[#111111]" />
                </div>
                <p className="font-bold text-lg">OUTSTATION ROUND TRIP</p>
                <p className="text-center">
                  Airport and Railway station Pick up and Drop 24/7 any time
                  service available.
                </p>
                <Link to="/contact">
                  <button
                    className="px-14 uppercase font-bold text-black hover:text-black"
                    style={{ background: "white" }}
                  >
                    Book Now
                  </button>
                </Link>
              </div>
              <div className="flex flex-col items-center justify-between gap-5">
                <div className="h-28 w-28 gradient p-5 rounded-full flex items-center justify-center">
                  <FaPlane className="w-full h-full text-[#111111]" />
                </div>
                <p className="font-bold text-lg">AIRPORT TRANSFER</p>
                <p className="text-center">
                  Airport and Railway station Pick up and Drop 24/7 any time
                  service available.
                </p>
                <Link to="/contact">
                  <button
                    className="px-14 uppercase font-bold text-black hover:text-black"
                    style={{ background: "white" }}
                  >
                    Book Now
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-20 bg-white">
        <p className="lg:text-6xl text-3xl font-bold mb-16">
          Round Trip Sevices
        </p>
        <div className="2xl:w-3/5 xl:w-3/4 w-11/12 grid xl:grid-cols-4 md:grid-cols-2 gap-10">
          <div className="rounded-md gradient p-5 text-center hover:-translate-y-2 hover:drop-shadow-lg">
            <img src={Swift} alt="" />
            <p className="text-2xl font-bold">Swift Dezire</p>
            <p className="font-medium">4 persons 3 Bags</p>
            <p className="text-3xl font-black border-t mt-10 mb-7 pt-5">
              Rs. 11/KM
            </p>
            <a
              href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
              target="_blank"
              rel="noreferrer"
            >
              <button className="uppercase font-bold text-white hover:text-black bg-black hover:bg-white py-4">
                Book Now
              </button>
            </a>
          </div>
          <div className="rounded-md gradient p-5 text-center hover:-translate-y-2">
            <img src={Ertiga} alt="" />
            <p className="text-2xl font-bold">Eartiga</p>
            <p className="font-medium">6 persons 4 Bags</p>
            <p className="text-3xl font-black border-t mt-10 mb-7 pt-5">
              Rs. 13/KM
            </p>
            <a
              href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
              target="_blank"
              rel="noreferrer"
            >
              <button className="uppercase font-bold text-white hover:text-black bg-black hover:bg-white py-4">
                Book Now
              </button>
            </a>
          </div>
          <div className="rounded-md gradient p-5 text-center hover:-translate-y-2">
            <img src={Innova} alt="" />
            <p className="text-2xl font-bold">Innova</p>
            <p className="font-medium">6 persons 4 Bags</p>
            <p className="text-3xl font-black border-t mt-10 mb-7 pt-5">
              Rs. 16/KM
            </p>
            <a
              href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
              target="_blank"
              rel="noreferrer"
            >
              <button className="uppercase font-bold text-white hover:text-black bg-black hover:bg-white py-4">
                Book Now
              </button>
            </a>
          </div>
          <div className="rounded-md gradient p-5 text-center hover:-translate-y-2">
            {" "}
            <img src={Innova2} alt="" />
            <p className="text-2xl font-bold">Crysta</p>
            <p className="font-medium">6 persons 4 Bags</p>
            <p className="text-3xl font-black border-t mt-10 mb-7 pt-5">
              Rs. 18/KM
            </p>
            <a
              href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
              target="_blank"
              rel="noreferrer"
            >
              <button className="uppercase font-bold text-white hover:text-black bg-black hover:bg-white py-4">
                Book Now
              </button>
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-20 bg-white">
        <p className="2xl:w-3/5 xl:w-3/4 w-11/12 lg:text-6xl text-3xl font-bold mb-16 text-center lg:leading-[5rem]">
          One Way Cab Services
        </p>
        <div className="2xl:w-3/5 xl:w-3/4 w-11/12 grid xl:grid-cols-3 md:grid-cols-2 gap-10">
          {carData.map((item, index) => {
            return (
              <div
                className="rounded-md p-5 text-center flex flex-col justify-center items-center gap-5 border"
                key={index}
              >
                <img src={Swift} alt="" />
                <p className="text-xl font-bold">{item.name}</p>
                <div className="lg:w-2/3 w-11/12 flex justify-between">
                  <p>Sedan:</p>
                  <p>{item.sedan}</p>
                </div>
                <div className="lg:w-2/3 w-11/12 flex justify-between">
                  <p>Suv:</p>
                  <p>{item.suv}</p>
                </div>
                <p className="text-xl lg:w-1/2 w-11/12">
                  (Toll + Driver charge Inclusive)
                </p>
                <a
                  href="https://wa.me/919510429400?text=Hello%20Jainil%20Travels%2C%20I%20want%20a%20texi%20service."
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="my-5 uppercase font-bold text-white hover:text-black bg-black hover:bg-white py-4 gradient">
                    Book Now
                  </button>
                </a>
              </div>
            );
          })}
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-20 bg-[#ECEAEA]">
        <p className="lg:text-6xl text-3xl font-bold mb-16">
          Round Trip Sevices
        </p>
        <div className="2xl:w-3/5 xl:w-3/4 w-11/12 grid xl:grid-cols-3 gap-10">
          <div className="rounded-md gradient p-5 py-7">
            <img src={card1} alt="" />
            <p className="text-xl font-bold my-5">ZERO CANCELLATION</p>
            <p className="text-justify">
              If your plans change, we understand you. Now you can cancel your
              booking free of charge up to 8 hours prior to the scheduled
              pick-up time.
            </p>
          </div>
          <div className="rounded-md gradient p-5 py-7">
            <img src={card2} alt="" />
            <p className="text-xl font-bold my-5">ONE WAY</p>
            <p className="text-justify">
              Now enjoy your journey on your one-way trip. Book a cab for a
              one-way trip anywhere without paying the fare for a round-trip
              journey.
            </p>
          </div>
          <div className="rounded-md gradient p-5 py-7">
            <img src={card3} alt="" />
            <p className="text-xl font-bold my-5">24X7 SERVICE</p>
            <p className="text-justify">
              We are here to help round-the-clock. A dedicated 24×7 customer
              support team always at your service to help solve any problem.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
