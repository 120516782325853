import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div className="my-10">
        <p className="text-white text-[10rem] lg:text-[20rem] text-center font-bold leading-none">
          404
        </p>
        <p className="text-3xl lg:text-5xl text-center text-white">
          Page Not Found
        </p>
        <div className="my-10 flex justify-center items-center text-white">
          <Link to="/">
            <button className="bg-accent">Go to Home</button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
