import React from "react";
import { HeadBanner } from "../components";

const RoundTrip = () => {
  return (
    <>
      <HeadBanner title="Round Trip Cab" />
      <div className="flex flex-col justify-center items-center py-20 bg-white">
        <div className="xl:w-3/5 w-11/12">
          <p className="lg:text-5xl mb-20 font-bold text-center">ROUND TRIP CAB</p>
          <details className="group marker:content-[''] border rounded-md my-10 " open>
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              WHAT IS A ROUND-TRIP RIDE?
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  A round-trip journey is any journey from a pickup point to the
                  desired destination and back to your doorstep! Book your next
                  roundtrip service with Jainil Travels to avail the
                  best-in-class taxi services in India at budget conscious
                  fares. Our taxi fares for round trip services are
                  comprehensive of all tolls, state taxes, driver charges and
                  other miscellaneous taxes. Book now! With our extensive fleet
                  of vehicles, you can choose from a variety of quality cabs as
                  per your requirement.
                </p>
              </div>
            </div>
          </details>
          <details className="group marker:content-[''] border rounded-md my-10 ">
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              TODAY IS THAT DAY TO CALL
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  Our customer support team will go above and beyond to meet
                  your every need. We are here to help round-the-clock.
                  Satisfying our travelers during their journey as well as after
                  their journey is of crucial importance to us. Reach out to us
                  for any query and we will resolve it at the earliest.
                </p>
              </div>
            </div>
          </details>
          <details className="group marker:content-[''] border rounded-md my-10 ">
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              FAIR AND TRANSPARENT RATE
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  As we provide quality services and a selection from a vast
                  fleet of vehicles, we also make sure that our fares our
                  economically beneficial to our customers. We ensure that the
                  fee charged is transparent, fair and competitive with what
                  other travel agencies charge.
                </p>
              </div>
            </div>
          </details>
          <details className="group marker:content-[''] border rounded-md my-10 ">
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              CONSISTENT DRIVERS:
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  Our services transcend all other travel agencies because we
                  ensure that are travelers are in safe and capable hands to
                  provide a satisfying journey experience with us. A rigorous
                  process of selection and background check is undertaken before
                  any of our driver is behind the wheel.
                </p>
              </div>
            </div>
          </details>
          <details className="group marker:content-[''] border rounded-md my-10 ">
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              ROUND-TRIP CAB SERVICE THAT'S THE CLOSEST TO YOU:
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  Our skilled cab drivers, without any difficulty will manage
                  your round-trips no matter how complicated or tedious the
                  journey may be. Simply book a “cab near me” and you’ll be
                  picked up, driven to your location, and return back to your
                  pick up point at the agreed upon time-frame.
                </p>
              </div>
            </div>
          </details>
          <details className="group marker:content-[''] border rounded-md my-10 ">
            <summary className="p-5 flex w-full cursor-pointer select-none justify-between text-left text-lg font-semibold leading-7 text-slate-900 group-open:text-black group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              THIS IS WHEREVER ARYAN TAXI WILL HELP YOU:
              <svg
                className="mt-0.5 ml-4 h-6 w-6 flex-none stroke-slate-700 group-open:stroke-accent"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M18 12H6" />
                <path className="group-open:hidden" d="M12 6v12" />
              </svg>
            </summary>
            <div className="pb-6 p-5 pt-0 group-open:bg-gradient-to-r from-[#c6c600] to-[#ff8040]">
              <div className="prose prose-slate max-w-none prose-a:font-semibold prose-a:text-indigo-600 hover:prose-a:text-indigo-500">
                <p className="leading-loose">
                  Enjoy a hassle-free experience by travelling with Jainil
                  Travels. Simply after searching for a “cab near me”, choose to
                  travel with us as we provide secure and easy payment options
                  through your registered account. Why delay any further? Book a
                  pleasant, affordable and safe travel with Jainil Travels.
                </p>
              </div>
            </div>
          </details>
        </div>
      </div>
    </>
  );
};

export default RoundTrip;
